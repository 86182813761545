var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c(
        "div",
        { staticClass: "q-pa-lg" },
        [
          _c("br"),
          _vm._m(0),
          _c("br"),
          _vm._l(_vm.analisi_rischio, function (Domanda, index) {
            return _c(
              "div",
              _vm._b(
                {
                  key: index,
                  staticClass: "col-md-5 col-12",
                  attrs: { align: "center" },
                },
                "div",
                Domanda,
                false
              ),
              [
                _c(
                  "fieldset",
                  [
                    _c("span", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(Domanda.label)),
                    ]),
                    _c("QQQuestionario", {
                      attrs: {
                        domande: Domanda.domande,
                        analisi_necessita: _vm.analisi_necessita,
                      },
                      on: {
                        "error-checked": function ($event) {
                          return _vm.onErrorChecked($event)
                        },
                        "qq-questionario-change": function ($event) {
                          return _vm.onDomandaChanges($event)
                        },
                      },
                      model: {
                        value: _vm.analisi_rischio,
                        callback: function ($$v) {
                          _vm.analisi_rischio = $$v
                        },
                        expression: "analisi_rischio",
                      },
                    }),
                  ],
                  1
                ),
                _c("br"),
              ]
            )
          }),
          _c("div", { staticClass: "row justify-center" }, [
            _c(
              "div",
              { staticClass: "col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "INDIETRO",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "AVANTI",
                    color: "blue-grey",
                    icon: "mdi-arrow-right-bold",
                    size: "md",
                    icon_align: "right",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onAvantiClicked.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Rinnovo Contratto - Analisi del Rischio"),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }